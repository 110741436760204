@charset "utf-8";

@import "minimal-mistakes/skins/air";
@import "minimal-mistakes";

.recent_posts_container {
  width: 70%;
  margin-top: 1%;
  margin-left: auto;
  margin-right: auto;
}

.recent_posts_block {
  overflow: hidden;
  height: 150px;
}

.recent_posts_img {
  width: 192px;
  height: 130px;
  float: left;
  margin-right: 10px;
}

.recent_posts_desc {
  overflow: hidden;
  margin-left: 10px;
  vertical-align: top;

  h2 {
    margin-top: auto !important;
  }
}

.pagination {
  float: unset;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.pagination--pager {
  padding: 0em 2em;
  width: max-content;
}
